import styled from '@emotion/styled';
import BeatLoader from 'react-spinners/BeatLoader';
import { colors } from 'styles/colors';

interface LoadingProps {
  text?: string;
}

const Loading = ({ text }: LoadingProps) => {
  return (
    <LoadingContainer>
      <BeatLoader color={colors.PRIMARY} />
      {text && <span>{text}</span>}
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  font-weight: 600;
  color: ${colors.INDIGO1};
`;

export default Loading;
