import { Global } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import AppRoutes from 'routes/AppRoutes';
import globalStyles from './styles/global.styles';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <>
      <Global styles={globalStyles} />

      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <AppRoutes />
          </RecoilRoot>

          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
