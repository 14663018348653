import styled from '@emotion/styled';
import { colors } from 'styles/colors';

export const PersonIconWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

export const PopOverBlock = styled.div`
  position: absolute;
  transform: translateY(100%);
  bottom: -12px;
  right: 0;
  cursor: pointer;

  width: 147px;
  height: 56px;

  padding: 16px 30px;

  background: ${colors.WHITE};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.BLACK3};

  &:hover {
    background: ${colors.GRAY5};
  }
`;
