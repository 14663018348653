import { ReactComponent as Logo } from 'assets/images/logo2.svg';
import { Header, HeaderBlock, Main } from './AppLayout.styles';
import PersonCircle from 'components/AppLayout/PersonCircle/PersonCircle';
import { Suspense, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'atoms/user';

const AppLayout = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  const onGoHome = useCallback(() => navigate('/'), [navigate]);

  return (
    <>
      <Header>
        <HeaderBlock>
          <Logo onClick={onGoHome} />
          {user && <PersonCircle />}
        </HeaderBlock>
      </Header>
      <Main>
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
      </Main>
    </>
  );
};

export default AppLayout;
