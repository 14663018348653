export const colors = {
  /** #FFFFFF */
  WHITE: '#FFFFFF',
  /** #F7F9FF */
  WHITE1: '#F7F9FF',

  /** #000000 */
  BLACK: '#000000',
  /** #333333 */
  BLACK3: '#333333',
  /** #444444 */
  BLACK4: '#444444',
  /** #666666 */
  BLACK6: '#666666',
  /** #888888 */
  BLACK8: '#888888',
  /** #999999 */
  BLACK9: '#999999',

  /** #0070E8 */
  PRIMARY: '#0070E8',

  /** #003369 */
  INDIGO1: '#003369',
  /** #001A77 */
  INDIGO2: '#001A77',

  /** #F1F1F1 */
  GRAY1: '#F1F1F1',
  /** #DBDBDB */
  GRAY2: '#DBDBDB',
  /** #D9D9D9 */
  GRAY3: '#D9D9D9',
  /** #F2F2F2 */
  GRAY4: '#F2F2F2',
  /** #FAFAFA */
  GRAY5: '#FAFAFA',

  /** #FAE100 */
  KAKAO: '#FAE100',
  /** #E10000 */
  RED: '#E10000',
  /** #FF0000 */
  RED1: '#FF0000',
} as const;
