import { useEffect } from 'react';
import { useGetUser } from './react-query/useAuth';

const useInitialize = () => {
  const { mutate: getUser, isLoading } = useGetUser();

  useEffect(() => {
    getUser();
  }, [getUser]);

  return { isLoading };
};

export default useInitialize;
