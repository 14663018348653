import { client } from 'api/client';
import { User } from 'atoms/user';
import {
  ChangePasswordParams,
  ChangePasswordResponse,
  LoginParams,
  LoginResponse,
} from './auth.types';

export const login = async (params: LoginParams) => {
  const { data } = await client.post<LoginResponse>('/auth/login', params);
  return data;
};

export const getUser = async () => {
  const { data } = await client.get<User>('/auth');
  return data;
};

export const changePassword = async (params: ChangePasswordParams) => {
  const { data } = await client.post<ChangePasswordResponse>(
    '/auth/change',
    params
  );
  return data;
};
