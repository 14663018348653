import { useMutation } from '@tanstack/react-query';
import { changePassword, getUser as getUserApi, login } from 'api/auth/auth';
import { ChangePasswordParams, LoginParams } from 'api/auth/auth.types';
import { userState } from 'atoms/user';
import { UseFormSetError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

export const useLogin = (setError: UseFormSetError<LoginParams>) => {
  const { mutate: getUser } = useGetUser();

  return useMutation(login, {
    onSuccess: ({ error }) => {
      if (error) {
        return setError('root', { message: error });
      }

      getUser();
    },
  });
};

export const useGetUser = () => {
  const setUser = useSetRecoilState(userState);

  return useMutation(getUserApi, {
    onSuccess: (user) => {
      setUser(user ? user : null);
    },
  });
};

export const useChangePassword = (
  setError: UseFormSetError<ChangePasswordParams>
) => {
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();

  return useMutation(changePassword, {
    onSuccess: ({ error }) => {
      if (error) {
        return setError('root', { message: error });
      }
      alert('비밀번호가 변경되었습니다. 다시 로그인해주세요.');
      setUser(null);
      navigate('/', { replace: true });
    },
  });
};
