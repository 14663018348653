import { userState } from 'atoms/user';
import AppLayout from 'components/AppLayout/AppLayout';

import { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useInitialize from 'hooks/useInitialize';
import Loading from 'components/Loading';

const Login = lazy(() => import('pages/Login/Login'));
const PasswordChange = lazy(
  () => import('pages/PasswordChange/PasswordChange')
);
const Search = lazy(() => import('pages/Search/Search'));
const SearchResult = lazy(() => import('pages/SearchResult/SearchResult'));
const NotFound = lazy(() => import('pages/NotFound'));

const AppRoutes = () => {
  const user = useRecoilValue(userState);

  const { isLoading } = useInitialize();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          {user ? (
            <>
              <Route path="/" element={<Search />} />
              <Route path="/search" element={<SearchResult />} />
              <Route path="/password" element={<PasswordChange />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
