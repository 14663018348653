import { ReactComponent as PersonCircleIcon } from 'assets/images/person_circle.svg';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PersonIconWrapper, PopOverBlock } from './PersonCircle.styles';

const PersonCircle = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const clickListener = () => setVisible(false);

    window.addEventListener('click', clickListener);

    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, []);

  const onToggle = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setVisible((prev) => !prev);
    },
    []
  );

  const onNavigatePasswordChange = useCallback(() => {
    navigate('/password');
  }, [navigate]);

  return (
    <PersonIconWrapper onClick={onToggle}>
      <PersonCircleIcon />
      {visible && (
        <PopOverBlock onClick={onNavigatePasswordChange}>
          비밀번호 변경
        </PopOverBlock>
      )}
    </PersonIconWrapper>
  );
};

export default PersonCircle;
