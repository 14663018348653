import styled from '@emotion/styled';
import { colors } from 'styles/colors';
import media from 'styles/media';

export const Header = styled.header`
  position: sticky;
  top: 0;

  background: ${colors.WHITE};
  border-bottom: 1px solid ${colors.GRAY4};
  padding: 16px 20px;

  ${media.desktop} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Main = styled.main`
  padding: 0 20px 20px;

  ${media.desktop} {
    padding: 0;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
`;
